export const props = () => ({
  apiUrl: "https://api-xpto.graodireto.com.br",
  logo: "graodireto",
  appTitle: "Barter Fácil",
  theme: "graodireto",
  companyLanguage: "default",
  keyCloakConfig: {
    url: "https://auth.graodireto.com.br",
    realm: "xpto",
    clientId: "xpto-corporate-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  hotjarConfig: "",
  nps: {
    api: "https://api.graodireto.com.br/api",
    url: "https://graodireto.com.br/nps",
    key: "109d2ffc-e06b-4c6a-88c2-6a1b9095dc75",
  },
});
